<template>
  <div v-if="currentWorkshop">
    <section class="workshop">
      <header class="workshop__header workshop__header--padding-bottom">
        <div class="workshop__headerWrap">
          <h1
            class="workshop__title"
            v-html="toHtml(currentWorkshop.fields.title)"
          ></h1>

          <a
            class="btn"
            v-if="currentWorkshop.fields.contactEmail"
            :href="'mailto:' + currentWorkshop.fields.ctaLink"
            >{{ currentWorkshop.fields.cta }}
          </a>
        </div>
      </header>

      <div class="workshop__body workshop__body--main">
        <div class="workshop__content">
          <div v-html="toHtml(currentWorkshop.fields.descriptionLong)"></div>
        </div>
        <div>
          <div class="workshop__visual">
            <img
              :src="
                updateImageUrl(
                  `${currentWorkshop.fields.mainVisual.fields.file.url}?w=400`,
                )
              "
              v-if="currentWorkshop.fields.mainVisual"
              :alt="currentWorkshop.fields.mainVisual.fields.title"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
const marked = require('marked');

export default {
  created: function() {
    this.$store.dispatch(
      'setWorkshop',
      this.$route.params.workshopSlug.toLowerCase(),
    );
  },
  watch: {
    $route() {
      this.setContent();
    },
  },
  components: {},
  data() {
    return {
      showError: false,
      isOpen: false,
      isOpenGallery: false,
      showContactForm: false,
      options: {
        index: 0,
      },
      optionsGallery: {},
    };
  },
  mounted() {},
  computed: {
    pageSlug: function() {
      // some tricky stuff with slugs and stuff
      let pageSlug =
        this.$route.params.workshopSlug.toLowerCase() || this.$route.name;

      return pageSlug;
    },
    images: function() {
      const images = [];
      if (this.currentWorkshop.fields.visuals) {
        for (let i = 0; i < this.currentWorkshop.fields.visuals.length; i++) {
          const visual = this.currentWorkshop.fields.visuals[i];
          const aspectRatio =
            visual.fields.file.details.image.width /
            visual.fields.file.details.image.height;
          images.push({
            src: `${visual.fields.file.url}?w=1000`,
            w: 1000,
            h: 1000 / aspectRatio,
            title: visual.fields.description,
          });
        }
      }
      return images;
    },
    ...mapGetters(['currentWorkshop', 'language']),
    ...mapState(['website', 'currentSite']),
  },
  methods: {
    toHtml: function(text) {
      return marked(text);
    },
    setContent: function() {
      this.$store.dispatch('setPage', { slug: this.pageSlug });
    },
    showPhotoSwipe(index) {
      this.isOpen = true;
      this.$set(this.options, '_index.php', index);
    },
    hidePhotoSwipe() {
      this.isOpen = false;
    },
  },
  metaInfo() {
    //check data before using its properties, and when data will be loaded metaInfo will update object as well:
    const currentPage = this.currentWorkshop;
    const currentSite = this.currentSite;

    console.log('currentpage', currentPage);
    return {
      title: currentPage ? currentPage.fields.title : '',
      titleTemplate: currentSite + ' Arena - %s',
      meta: [
        { charset: 'utf-8' },
        {
          name: 'og:title',
          content: currentPage ? currentPage.fields.title : '',
        },
        {
          name: 'og:description',
          content: currentPage ? currentPage.fields.description : '',
        },
        {
          name: 'description',
          content: currentPage ? currentPage.fields.description : '',
        },
        {
          name: 'og:image',
          content:
            currentPage && currentPage.fields.mainVisual
              ? currentPage.fields.mainVisual.fields.file.url
              : './track.jpg',
        },
      ],
      htmlAttrs: {
        lang: 'en',
        amp: true,
      },
    };
  },
};
</script>
